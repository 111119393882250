import Header from "./components/Header";
import "./styles/style.scss";
import "./styles/media.scss";
import Menu from "./components/Menu";
import Family from "./components/Family";
import Contact from "./components/Contact";
import Instagram from "./components/Instagram";
import Map from "./components/Map";
import Alert from "./components/Alert";

function App() {
  return (
    <div className="App">
      <Menu />
      <Header />
      <Family />
      <Contact />
      <Instagram />
      <Map />
      <Alert />
      <footer>
        <div className="text">Alibiroom.lv © All rights reserved</div>
      </footer>
    </div>
  );
}

export default App;
