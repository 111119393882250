import React, { useState } from "react";
import classNames from "classnames";
import { Loading } from "../svg";
import axios from "axios";
function Overlay({ overlay, setOverlay }) {
  const [status, setStatus] = useState(false);
  const submitForm = (e) => {
    e.preventDefault();
    setStatus("sending");
    axios
      .post("/index.php")
      .then(({ data }) => {
        console.log({ data });
        setStatus("sent");
      })
      .catch(() => {
        setStatus("sent");
      });
  };
  return (
    <div
      className={classNames("overlay", {
        active: overlay,
      })}
    >
      <div
        className={classNames("ty", {
          active: !!status,
          loaded: status === "sent",
        })}
      >
        <div className="close-x" onClick={() => {}}>
          <div></div>
          <div></div>
        </div>
        <Loading />
        <h2>Success!</h2>
        <div className="text">
          Thank you for filling out the form. If you have left your email, we
          will contact you if necessary :)
        </div>
        <button className="classic" onClick={() => setOverlay(false)}>
          back to homepage
        </button>
      </div>
      <div
        className={classNames("pop", {
          active: !status,
        })}
      >
        <div className="close-x" onClick={() => setOverlay(false)}>
          <div></div>
          <div></div>
        </div>
        <h2>Share your customer experience with us</h2>
        <form id="email-form">
          <textarea
            placeholder="Type your text here…"
            name="message"
            required
          ></textarea>
          <div className="label">
            Want to recieve an answer, enter email address.
          </div>
          <input type="email" name="email" placeholder="Your email address" />
          <button className="classic send" onClick={(e) => submitForm(e)}>
            send now
          </button>
        </form>
      </div>
    </div>
  );
}

export default Overlay;
