import React, { useState } from "react";
import ContactBg from "../images/contact.jpg";
import Overlay from "./Overlay";
function Contact(triggerPop) {
  const [overlay, setOverlay] = useState(false);
  return (
    <>
      <Overlay overlay={overlay} setOverlay={setOverlay} />
      <section
        className="contact"
        style={{
          backgroundImage: `url(${ContactBg})`,
        }}
      >
        <h2>Share your customer experience with us</h2>
        <div className="h2-sub">
          ask a question, share an idea or suggest cooperation
        </div>
        <button className="classic" onClick={() => setOverlay(true)}>
          CONTACT US
        </button>
      </section>
    </>
  );
}

export default Contact;
