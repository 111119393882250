import React from "react";
import { User } from "../svg";

function FamilyMember({ name, role, photo }) {
  return (
    <div className="box">
      <div className="image-container">
        {photo ? (
          <img src={photo} />
        ) : (
          <div className="image-container-placeholder">
            <User />
          </div>
        )}
      </div>
      <div className="text-container">
        <div className="name">{name}</div>
        <div className="role">{role}</div>
      </div>
    </div>
  );
}

export default FamilyMember;
