import React from "react";
import { Photo } from "../svg";

function InstagramItem({ permalink, media_url }) {
  return (
    <a
      href={permalink}
      target="_blank"
      className="instagram-container"
      style={{
        backgroundImage: `url(${media_url})`,
      }}
    >
      <div className="overlay-inst">
        <Photo />
      </div>
    </a>
  );
}

export default InstagramItem;
