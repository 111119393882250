import React from "react";
import { Logo } from "../svg";

function Map() {
  return (
    <section className="map">
      <div id="map">
        <iframe
          className="gmap_iframe"
          width="100%"
          height="100%"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Mazā Smilšu iela 17, Centra rajons, Rīga, LV-1050&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        />
      </div>
      <div className="box">
        <Logo />
        <div className="data-container">
          <span className="data">Address:</span>
          <span className="data">Mazā smilšu iela 17</span>
        </div>
        <div className="data-container">
          <span className="data">Phone number:</span>
          <span className="data">+ 371 2000 6233</span>
        </div>
        <div className="work-time lol">
          <span className="data">
            Sunday - Thursday: <span>13:00 - 00:00</span>
          </span>
        </div>
        <div className="work-time">
          <span className="data">
            Friday - Saturday: <span>13:00 - 2:00</span>
          </span>
        </div>
        <a
          href="https://www.google.com/maps/dir//Alibi+Room+Hookah+and+Cocktail+Bar,+Mazā+Smilšu+iela+17,+Centra+rajons,+Rīga,+LV-1050/@56.9504355,24.111357,16z/data=!4m7!4m6!1m0!1m3!2m2!1d24.10975!2d56.9503056!3e0"
          target="_blank"
        >
          <button className="classic">get direction</button>
        </a>
      </div>
    </section>
    // <div>
    //   <iframe
    //     class="gmap_iframe"
    //     width="100%"
    //     frameborder="0"
    //     scrolling="no"
    //     marginheight="0"
    //     marginwidth="0"
    //     src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Mazā Smilšu iela 17, Centra rajons, Rīga, LV-1050&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
    //   />
    // </div>
  );
}

export default Map;
