import React, { useEffect, useState } from "react";
import Instafeed from "instafeed.js";
import InstagramItem from "./InstagramItem";
const token =
  "IGQVJWdlFnc09SR3ByaldHcENEOE9DWHB5Q1ZAUY2xwNjlvSGduSGdDUHZAXQllGRHotMDVrU3J6Y1p4WDBZAc1dpdkl5N0pqeFpyWWVZAQUg4dG9aM1JYVjZApZATY4MDNDYm5jeFVRTi1ISXJOTGEyaWxpWQZDZD";
function Instagram() {
  const [images, setImages] = useState([]);
  useEffect(() => {
    var feed = new Instafeed({
      accessToken: token,
      limit: 8,
      success: ({ data }) => {
        setImages(data);
      },
      render: () => {},
    });
    feed.run();
  }, []);
  return (
    <section className="instagram">
      <h1>Our Instagram</h1>
      <div id="instafeed">
        {images.map((image, i) => (
          <InstagramItem {...image} key={i} />
        ))}
      </div>
      <a
        className="social"
        href="https://www.instagram.com/alibiroombar/"
        target="_blank"
      >
        <button className="classic">Follow our instagram</button>
      </a>
    </section>
  );
}

export default Instagram;
