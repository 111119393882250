import React, { useState } from "react";
import classNames from "classnames";

function Alert() {
  const [state, setState] = useState(true);
  return (
    <div
      className={classNames("covid", {
        hidden: !state,
      })}
    >
      <div>Working hours may vary because of covid restrictions</div>
      <div className="close-x" onClick={() => setState(false)}>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export default Alert;
