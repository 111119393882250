import React from "react";
import VideoBg from "../video/Alibi_Room_WEB.mp4#t=5,49";
function Header() {
  return (
    <header>
      <div className="background">
        <video id="video" muted autoPlay loop>
          <source src={VideoBg} type="video/mp4" />
        </video>
      </div>
      <div className="container">
        <h1>Premium Cocktail & Hookah bar</h1>
        <div className="h1-sub">
          Make a reservation by calling any convenient way for you
        </div>
        <a href="telegram://tel:+37120006233">
          <button className="classic">CALL with telegram</button>
        </a>
        <a href="whatsapp://tel:+37120006233">
          <button className="classic">CALL with WhatsApp</button>
        </a>
        <a href="tel:+37120006233">
          <button className="classic mobile">Reserve by call</button>
        </a>
        <a
          href="https://www.google.com/maps/dir//Alibi+Room+Hookah+and+Cocktail+Bar,+Mazā+Smilšu+iela+17,+Centra+rajons,+Rīga,+LV-1050/@56.9504355,24.111357,16z/data=!4m7!4m6!1m0!1m3!2m2!1d24.10975!2d56.9503056!3e0"
          target="_blank"
        >
          <button className="classic mobile">get direction</button>
        </a>
      </div>
      <div className="bottom">
        <div className="work-time">
          Sunday - Thursday: <span>13:00 - 00:00</span>
        </div>
        <div className="work-time">
          Friday - Saturday: <span>13:00 - 2:00</span>
        </div>
      </div>
    </header>
  );
}

export default Header;
