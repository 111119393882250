import React, { useState } from "react";
import { Facebook, Instagram, Logo, Phone, LogoPhone } from "../svg";
import classNames from "classnames";

function Menu() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div
        className={classNames("mobile-nav", {
          active: open,
        })}
      >
        <div className="container">
          <LogoPhone />
          <div className="burger" onClick={() => setOpen(!open)}>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>

        <div className="drop-menu" >
          <Logo />
          <div className="data-container">
            <span className="data">Address:</span>
            <span className="data">Mazā smilšu iela 17</span>
          </div>
          <div className="data-container">
            <span className="data">Phone number:</span>
            <span className="data">+ 371 2000 6233</span>
          </div>
          <div className="work-time lol">
            <span className="data">
              Sunday - Thursday: <span>13:00 - 00:00</span>
            </span>
          </div>
          <div className="work-time">
            <span className="data">
              Friday - Saturday: <span>13:00 - 2:00</span>
            </span>
          </div>
          <div className="work-time language">
            <span className="data">
              <a href="/">LV</a>
              <a href="/" className="active">EN</a>
            </span>
          </div>
          <a
            href="https://www.google.com/maps/dir//Alibi+Room+Hookah+and+Cocktail+Bar,+Mazā+Smilšu+iela+17,+Centra+rajons,+Rīga,+LV-1050/@56.9504355,24.111357,16z/data=!4m7!4m6!1m0!1m3!2m2!1d24.10975!2d56.9503056!3e0"
            target="_blank"
          >
            <button className="classic">get direction</button>
          </a>
          <a href="tel:+37120006233">
            <button className="classic mobile">Reserve by call</button>
          </a>
        </div>
      </div>
      <div className="nav">
        <div className="container">
          <a
            className="social"
            href="https://www.facebook.com/AlibiRoomLV/"
            target="_blank"
          >
            <Facebook />
            <span>Facebook</span>
          </a>
          <a
            className="social"
            href="https://www.instagram.com/alibiroombar/"
            target="_blank"
          >
            <Instagram />
            <span>Instagram</span>
          </a>
          <span className="social last">
            <span className="social-language">
              <a className="active" href="/">EN</a>
              <a href="/">LV</a>
            </span>
            <Phone />
            <span>+ 371 2000 6233</span>
          </span>
        </div>
        <Logo />
      </div>
    </>
  );
}

export default Menu;
