import React from "react";
import FamilyData from "../data/family.json";
import FamilyMember from "./FamilyMember";
function Family() {
  console.log(FamilyData);
  return (
    <section className="family">
      <h1>Alibi Room Family</h1>
      <div className="container">
        {FamilyData.map((member, i) => (
          <FamilyMember {...member} key={i} />
        ))}
      </div>
    </section>
  );
}

export default Family;
